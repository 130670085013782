import type {ImgHTMLAttributes} from 'react';

const Image = (props: ImgHTMLAttributes<HTMLImageElement>) => {
  if (props.srcSet && typeof props.srcSet !== 'string') {
    console.error(
      `shared/Image component srcSet prop must be of type "string": ${props.srcSet}`,
    );
    return null;
  }

  const isResponsive = props.srcSet && props.srcSet.includes('@artdirection');
  const [mobileSrc, , desktopSrc] =
    props.srcSet && props.srcSet.includes('1x')
      ? props.srcSet.split(' ')
      : [props.srcSet, '', props.srcSet];

  return isResponsive ? (
    <picture>
      <source media="(max-width: 899px)" srcSet={mobileSrc} />
      <source media="(min-width: 900px)" srcSet={desktopSrc} />
      <img {...props} src={desktopSrc} alt={props.alt ?? ''} />
    </picture>
  ) : (
    <img
      {...props}
      alt={props.alt ?? ''}
      sizes={props.sizes ?? '100vw'}
      srcSet={props.srcSet}
    />
  );
};

export default Image;
